var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"app_bar_desktop",attrs:{"fixed":"","color":"rgba(0,0,0,.7)","elevation":"0","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"div_img_maguey_b",attrs:{"src":require("../assets/magueyB.png")}})],1)],1)],1),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home-outline")])],1),_c('v-list-item-title',[_vm._v("Home")])],1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-calendar-multiple","color":"#f6b81d","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{attrs:{"color":"pink--text"}},[_vm._v("Eventos")])]},proxy:true}])},_vm._l((_vm.eventos),function(ref,i){
var name = ref[0];
var to = ref[1];
return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('router-link',{staticClass:"link",attrs:{"to":to}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(name)}})],1)],1)}),1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book-account-outline")])],1),_c('v-list-item-title',{on:{"click":function($event){return _vm.scrollTo('notas')}}},[_vm._v("Prensa")])],1),_c('v-list-item',{on:{"click":_vm.fnToConocenos}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-head-dots-horizontal-outline")])],1),_c('v-list-item-title',[_vm._v("Conócenos")])],1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-face-agent","color":"#f6b81d","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{attrs:{"color":"pink--text"}},[_vm._v("Atención")])]},proxy:true}])},_vm._l((_vm.atencion),function(ref,i){
var name = ref[0];
var to = ref[1];
return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('router-link',{staticClass:"link",attrs:{"to":to}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(name)}})],1)],1)}),1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-account-child-outline","color":"#f6b81d","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{attrs:{"color":"pink--text"}},[_vm._v("Account Manager")])]},proxy:true}])},_vm._l((_vm.account),function(ref,i){
var name = ref[0];
var to = ref[1];
return _c('v-list-item',{key:i,attrs:{"href":to,"target":"_blank","link":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(name)}})],1)}),1),_c('v-list-item',{attrs:{"href":"https://pagos.magueytoken.com/","target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cash-multiple")])],1),_c('v-list-item-title',[_vm._v("Comieza a invertir")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }