<template>
  <div class="main">
    <div class="container">
      <v-row>
        <v-col
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="900"
          class="div_col"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          cols="12"
        >
          <span class="text_span"> Proceso de producción del cacao </span>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                
                data-aos-delay="4"
                lazy-src="../assets/proceso2.png"
                src="../assets/proceso2.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-left"
                data-aos-duration="300"
                data-aos-delay="4"
                class="text_h2"
              >
                1.CORTE DE PENCA
              </h2>
              <h4
                data-aos="fade-right"
                data-aos-duration="300"
                data-aos-delay="4"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                Se realiza la jima, consiste en quitar todas las pencas y raíces
                hasta dejar el centro del maguey al descubierto.&nbsp;&nbsp;
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
                lazy-src="../assets/proceso3.png"
                src="../assets/proceso3.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-right"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h2"
              >
                2.CORTE
              </h2>
              <h4
                data-aos="fade-left"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                La piña se corta en trozoz más peque- ños para facilitar la
                cocción.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
                lazy-src="../assets/proceso4.png"
                src="../assets/proceso4.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-left"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h2"
              >
                3.COCCIÓN
              </h2>
              <h4
                data-aos="fade-right"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                A través de hornos cónicos de piedra y bajo tierra, las piñas se
                cuecen a fuego alto para obtener los azúcares prove- nientes del
                agave.
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
                lazy-src="../assets/proceso1.png"
                src="../assets/proceso1.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-right"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h2"
              >
                4.MOLIENDA
              </h2>
              <h4
                data-aos="fade-left"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                Se desmenuza y tritura el agave cocido para obtenerse los mostos
                que se utilizarán para la fermentación.
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
                lazy-src="../assets/proceso5.png"
                src="../assets/proceso5.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-left"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h2"
              >
                5.FERMENTACIÓN
              </h2>
              <h4
                data-aos="fade-right"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                Se transportan los mostos a tinas de madera de roble o encino
                que pueden tener capacidad hasta de dos mil litros. El proceso
                dura tres a cinco días.
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
                lazy-src="../assets/proceso7.png"
                src="../assets/proceso7.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-right"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h2"
              >
                6.DESTILACIÓN
              </h2>
              <h4
                data-aos="fade-left"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                Los mostos se pasan a los alambiques para llevar a cabo la
                destilación. En este proceso se elimina el agua y se concen- tra
                el alcohol.
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
                lazy-src="../assets/proceso8.png"
                src="../assets/proceso8.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-left"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h2"
              >
                7.ENVASADO
              </h2>
              <h4
                data-aos="fade-right"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                Los mezcaleros estandarizan el producto mediante la
                homogeneización y estabili- zación del mezcal. El ajuste del
                grado alcohólico, debe estar entre 36 y 55% alcohol sobre
                volumen.
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col xs="12" sm="3" md="3" lg="3" xl="4">
              <v-img
                :width="widthImg"
                data-aos="zoom-in-up"
                class="img1"
                data-aos-easing="ease-in-sine"
                data-aos-duration="200"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
                lazy-src="../assets/proceso6.png"
                src="../assets/proceso6.png"
              ></v-img>
            </v-col>
            <v-col xs="12" sm="9" md="9" lg="9" xl="8" class="div_col_text">
              <h2
                data-aos="flip-right"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h2"
              >
                8.ETIQUETADO
              </h2>
              <h4
                data-aos="fade-left"
                data-aos-duration="300"
                data-aos-delay="400"
                class="text_h4"
                data-aos-anchor-placement="center-bottom"
              >
                Se etiqueta con un marbete para bebidas alcohólicas que emite la
                Secre- taría de Hacienda y Crédito Público de México para marcar
                las botellas de contenido alcohólico.
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <svg
      id="waves_id"
      class="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g class="parallax">
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="0"
          fill="rgba(133,175,97,0.7)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="3"
          fill="rgba(133,175,97,0.5)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="5"
          fill="rgba(133,175,97,0.3)"
        />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#85AF61" />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  data: () => ({
    widthImg: "100",
  }),
  mounted() {
    console.log(window.innerWidth);
    if (window.innerWidth >= 1904) {
      this.widthImg = "273.48";
    } else if (window.innerWidth >= 1264 && window.innerWidth < 1904) {
      this.widthImg = 124.13;
    } else {
      this.widthImg = 100;
    }
    window.onresize = () => {
      console.log(window.innerWidth);

      if (window.innerWidth >= 1904) {
        this.widthImg = 273.48;
      } else if (window.innerWidth >= 1264 && window.innerWidth < 1904) {
        this.widthImg = 124.13;
      } else {
        this.widthImg = 100;
      }
    };
  },
};
</script>
<style scoped>
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@media screen and (min-width: 1904px) {
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .div_col_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div_col {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 5% 0;
  }

  .main {
    position: relative;
    /* background: linear-gradient(rgb(246, 184, 29), #000000 100%); */
    background-color: rgb(255, 255, 255);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  .container {
    background: white;
    width: 100%;
  }
  .img1,
  .img2,
  .img3,
  .img4,
  .img5,
  .img6,
  .img7,
  .img8 {
    width: 300px;
  }
  .text_h2 {
    margin: 0 10% 0 0;
    color: #85AF61;
  }
  .text_h4 {
    text-align: justify;
    font-size: 1em;
  }
  .text_span {
    font-size: 3em;
    color: #85AF61;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .div_col_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div_col {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 5% 0;
  }
  .container {
    background: white;
    width: 100%;
    height: 100%;
  }
  .main {
    position: relative;
    /* background: linear-gradient(rgb(246, 184, 29), #000000 100%); */
    background-color: rgb(255, 255, 255);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  .img1,
  .img2,
  .img3,
  .img4,
  .img5,
  .img6,
  .img7,
  .img8 {
    width: 300px;
  }
  .text_h2 {
    margin: 0 10% 0 0;
    color: #85AF61;
    font-size: 1.3em;
  }
  .text_h4 {
    text-align: justify;
    font-size: 0.9em;
  }
  .row {
    margin-bottom: 5%;
  }
  .text_span {
    font-size: 3em;
    color: #85AF61;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) {
  .waves {
    position: relative;
    width: 100%;
    height: 10vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .main {
    position: relative;
    /* background: linear-gradient(rgb(246, 184, 29), #000000 100%); */
    background-color: rgb(255, 255, 255);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  .text_h2 {
    margin: 0 10% 0 0;
    color: #85AF61;
    font-size: 1.3em;
  }
  .text_h4 {
    text-align: justify;
    font-size: 0.9em;
  }
  .container {
    background: white;
    width: 100%;
    height: 100%;
    margin-top: 10%;
  }
  .row {
    margin-bottom: 7%;
  }
  .div_col {
    margin-bottom: 10%;
  }
  .text_span {
    font-size: 3em;
    color: #85AF61;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .waves {
    position: relative;
    width: 100%;
    height: 1vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .main {
    position: relative;
    /* background: linear-gradient(rgb(246, 184, 29), #000000 100%); */
    background-color: rgb(255, 255, 255);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  .text_h2 {
    margin: 0 10% 0 0;
    color: #85AF61;
    font-size: 1.3em;
  }
  .text_h4 {
    text-align: justify;
    font-size: 0.9em;
  }
  .container {
    background: white;
    width: 600px;
    height: 90%;
    margin-top: 10%;
  }
  .div_col {
    text-align: center;
  }
  .text_span {
    font-size: 2em;
    color: #85AF61;
  }
  .row {
    padding: 0 10px 0 10px;
  }
}
@media screen and (max-width: 600px) {
  .waves {
    position: relative;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 5vh;
    max-height: 150px;
    z-index: 2;
  }
  .row {
    padding: 0 5px 0 5px;
  }
  .main {
    position: relative;
    /* background: linear-gradient(rgb(246, 184, 29), #000000 100%); */
    background-color: rgb(255, 255, 255);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  .container {
    background: white;
    height: 100%;
    margin-top: 10%;
    z-index: 2;
  }
  .div_col {
    text-align: center;
  }
  .text_span {
    font-size: 1.4em;
    color: #85AF61;
  }
  .text_h2 {
    color: #85AF61;
    font-size: 1em;
  }
  .text_h4 {
    text-align: justify;
    font-size: 0.7em;
  }
}
</style>