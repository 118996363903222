<template>
  <div class="main">
    <div class="container">
      <div class="div-icons">
        <div class="icons">
          <v-icon
            class="icon"
            dark
            v-for="(item, index) in icons"
            :key="index"
            >{{ item.name }}</v-icon
          >
        </div>
      </div>
      <div class="div-text">
        <div class="text">
          <p>
            Plaza Parque, Piso 3, Calzada Porfirio Díaz 258, colonia Reforma,
            Oaxaca de Juárez, Oaxaca. Central Park, Corporativo 2, Piso 14,
            Avenida Armando Birlain Shaffler 2001, Centro Sur, Santiago de
            Querétaro, Querétaro, México.
          </p>
          <p>
            Atención general: contacto@magueytoken.com / Teléfono: +52 55 6493
            1947
          </p>
          <p>
            {{ new Date().getFullYear() }} —<strong
              >BIO PLANTA ® Todos los derechos reservados 2021 |
              <router-link class="politicas" to="/politicas_de_privacidad"
                >Políticas de Privacidad</router-link
              ></strong
            ><br>Respaldado por el fondo de inversion cbi multiassets
          </p>
          <p ></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    icons: [
      {
        name: "mdi-facebook",
        link_page: "https://www.facebook.com/magueytoken/",
      },
      {
        name: "mdi-youtube",
        link_page:
          "https://www.youtube.com/channel/UCrdsvFCS-dxd262mt-NqA9Q/videos",
      },

      {
        name: "mdi-linkedin",
        link_page:
          "https://www.linkedin.com/showcase/magueytoken/?originalSubdomain=mx",
      },
      {
        name: "mdi-instagram",
        link_page: "https://www.instagram.com/magueytoken/?hl=es",
      },
    ],
  }),
};
</script>
<style scoped>
@media screen and (min-width: 1904px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1904px) and (min-height: 700px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) and (min-height: 700px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 35vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 10px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 700px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 16px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 35vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 10px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height:700px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 10px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 50vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 10px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 600px) and (min-height:800px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 10px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 600px) and (min-height:700px) and (max-height:800px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 10px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 600px) and (min-height:600px) and (max-height:700px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 30vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 9px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}
@media screen and (max-width: 600px) and (min-height:500px) and (max-height:600px) {
  .main {
    margin-top: 30%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 40vh;
    background-color: rgb(0, 0, 0);
  }
  .container {
    height: 100%;
  }
  .div-icons {
    width: 100%;
  }
  .icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin: 1%;
  }
  p {
    text-align: center;
    color: white;
    font-size: 10px;
  }
  .politicas {
    text-decoration: none;
    color: #85AF61;
  }
  .div-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>