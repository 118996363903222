<template>
  <div class="main">
    <div class="container">
      <v-row>
        <h1 style="margin-top: 100px">NOTAS PERIODÍSTICAS</h1>
        <v-col
          class="col_content nota1"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          data-aos="zoom-in-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <!-- <h2>Emprendimientos Fintech con impacto social en México</h2> -->
          <div
            class="div_logo"
            style="display: flex; justify-content: center; align-items: center"
          >
            <a
              target="_blank"
              href="https://yucatan.quadratin.com.mx/opinion/opinion-elsa-ontiveros/"
            >
              <img
                loading="lazy"
                src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594231/quadratin_uqeyed.png"
              />
            </a>
          </div>
          <v-row class="row_info">
            <v-col class="col_img" cols="12" sm="12" md="12" lg="12" xl="5">
              <div class="div_img">
                <a
                  target="_blank"
                  href="https://yucatan.quadratin.com.mx/opinion/opinion-elsa-ontiveros/"
                >
                  <v-img
                    loading="lazy"
                    :max-width="width"
                    :height="height"
                    class="img"
                    lazy-src="../assets/campo_maguey.jpg"
                    src="../assets/campo_maguey.jpg"
                  ></v-img
                ></a>
              </div>
            </v-col>
            <v-col class="col_text" cols="12" sm="12" md="12" lg="12" xl="7">
              <p>
                <strong
                  >Emprendimientos Fintech con impacto social en México</strong
                >
                <br /><br />
                Este proyecto se crea para incluir a pequeños ahorradores en
                nuevos modelos Fintech pero, sobre todo genera empleos en las
                comunidades con proyectos locales sostenibles que reactiven su
                misma economía, en el caso de Maguey Token invirtiendo en
                plantaciones de maguey para la producción de mezcal, una
                industria ancestral de los pueblos indígenas oaxaqueños.
              </p>
              <!-- <p class="text_copy notas">
                El texto original de este artículo fue publicado por la Agencia
                Quadratín en la siguiente dirección:
                https://yucatan.quadratin.com.mx/opinion/opinion-elsa-ontiveros/
                Este contenido se encuentra protegido por la ley. Si lo cita,
                por favor mencione la fuente y haga un enlace a la nota original
                de donde usted lo ha tomado. Agencia Quadratín. Todos los
                Derechos Reservados © 2018.
              </p> -->
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="col_content nota2"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          data-aos="zoom-in-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <!-- <h2>
            CBI Multiassets Presenta Maguey Token Investing Experience En Evento
            Exclusivo
          </h2> -->

          <div
            class="div_logo"
            style="display: flex; justify-content: center; align-items: center"
          >
            <a
              target="_blank"
              href="https://www.revistavidayestilo-oaxaca.com.mx/instantes/cbi-multiassets-presenta-maguey-token-investing-experience-en-evento-exclusivo/#:~:text=En%20un%20evento%20privado%20realizado,personalidades%20distinguidas%20de%20la%20ciudad."
            >
              <img
                loading="lazy"
                src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594233/vida_y_estilo_rchlhw.png"
              />
            </a>
          </div>

          <v-row class="row_info">
            <v-col class="col_img" cols="12" sm="12" md="12" lg="12" xl="5">
              <div class="div_img">
                <a
                  target="_blank"
                  href="https://www.revistavidayestilo-oaxaca.com.mx/instantes/cbi-multiassets-presenta-maguey-token-investing-experience-en-evento-exclusivo/#:~:text=En%20un%20evento%20privado%20realizado,personalidades%20distinguidas%20de%20la%20ciudad."
                >
                  <v-img
                    loading="lazy"
                    :max-width="width"
                    :height="height"
                    class="img"
                    lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594224/nota1_u6yr4s.jpg"
                    src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594224/nota1_u6yr4s.jpg"
                  ></v-img
                ></a>
              </div>
            </v-col>
            <v-col class="col_text" cols="12" sm="12" md="12" lg="12" xl="7">
              <p>
                <strong
                  >CBI Multiassets Presenta Maguey Token Investing Experience En
                  Evento Exclusivo</strong
                ><br /><br />
                Que es MagueyToken es un sistema de inversión que te permite ser
                dueño de un rancho de plantaciones de maguey en el Estado de
                Oaxaca, el origen del Mezcal. Hoy en día, la tendencia en el
                consumo de mezcal ha tenido un crecimiento exponencial.
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="col_content nota3"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <!-- <h2>
            Oaxaca, espacio de oportunidades: hacia una nueva cultura
            inmobiliaria
          </h2> -->
          <div
            class="div_logo"
            style="display: flex; justify-content: center; align-items: center"
          >
            <a
              href="https://www.youtube.com/watch?v=OCDbvjmcKA4"
              target="_blank"
            >
              <img
                loading="lazy"
                src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594224/nvi_noticias_qjnwb7.png"
              />
            </a>
          </div>
          <v-row class="row_info">
            <v-col class="col_img" cols="12" sm="12" md="12" lg="12" xl="5">
              <div class="div_img">
                <a
                  href="https://www.youtube.com/watch?v=OCDbvjmcKA4"
                  target="_blank"
                >
                  <v-img
                    loading="lazy"
                    :max-width="width"
                    :height="height"
                    class="img"
                    lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594225/nota3_f7xurz.jpg"
                    src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594225/nota3_f7xurz.jpg"
                  ></v-img
                ></a>
              </div>
            </v-col>
            <v-col class="col_text" cols="12" sm="12" md="12" lg="12" xl="7">
              <p>
                <strong
                  >Oaxaca, espacio de oportunidades: hacia una nueva cultura
                  inmobiliaria</strong
                ><br /><br />
                Oaxaca se transforma en un destino para la inversión, y el
                crecimiento de la industria inmobiliaria es muestra de ello. Es
                por eso que las y los interesados en formar parte de esta
                industria se capaciten de manera profesional. Oaxaca.
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="col_content nota4"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <!-- <h2>
            Oaxaca, espacio de oportunidades: hacia una nueva cultura
            inmobiliaria
          </h2> -->
          <div class="logo_doble">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 75px;
              "
            >
              <a
                target="blank"
                href="https://www.cbimultiassets.com/blog/cbi-multiassets-y-el-instituto-euroamericano-firman-alianza-para-crear-universidad-digital"
              >
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594219/logo_universidad_dca3vg.png"
                />
              </a>
              <div></div>
              <a
                target="blank"
                href="https://www.cbimultiassets.com/blog/cbi-multiassets-y-el-instituto-euroamericano-firman-alianza-para-crear-universidad-digital"
              >
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/duymuyggi/image/upload/v1630594219/logo_instituto_hsb92q.png"
                />
              </a>
            </div>
          </div>
          <v-row class="row_info">
            <v-col class="col_img" cols="12" sm="12" md="12" lg="12" xl="5">
              <div class="div_img">
                <a
                  target="blank"
                  href="https://www.cbimultiassets.com/blog/cbi-multiassets-y-el-instituto-euroamericano-firman-alianza-para-crear-universidad-digital"
                >
                  <v-img
                    loading="lazy"
                    :max-width="width"
                    :height="height"
                    class="img"
                    lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630973357/nota4_1_ctobdc.jpg"
                    src="https://res.cloudinary.com/duymuyggi/image/upload/v1630973357/nota4_1_ctobdc.jpg"
                  ></v-img
                ></a>
              </div>
            </v-col>
            <v-col class="col_text" cols="12" sm="12" md="12" lg="12" xl="7">
              <p>
                <strong
                  >CBI MULTIASSETS Y EL INSTITUTO EUROAMERICANO FIRMAN ALIANZA
                  PARA CREAR UNIVERSIDAD DIGITAL </strong
                ><br /><br />
                Como parte de la apertura digital, visión al futuro y educación
                de calidad, CBI MULTIASSETS anuncia su alianza con el Instituto
                Euroamericano para crear la “Universidad Euroamericana-CBI.
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="col_content nota5"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          data-aos="zoom-in-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1fff"
        >
          <!-- <h2>Emprendimientos Fintech con impacto social en México</h2> -->
          <div
            class="div_logo"
            style="display: flex; justify-content: center; align-items: center"
          >
            <a
              target="_blank"
              href="https://imparcialoaxaca.mx/opinion/568695/muerte-de-los-cetes-como-opcion-de-inversion/"
            >
              <img
                class="logo_imparcial"
                src="https://imparcialoaxaca.mx/wp-content/uploads/2021/09/logo_imparcialOaxaca_sitio.png"
              />
            </a>
          </div>
          <v-row class="row_info">
            <v-col class="col_img" cols="12" sm="12" md="12" lg="12" xl="5">
              <div class="div_img">
                <a
                  target="_blank"
                  href="https://imparcialoaxaca.mx/opinion/568695/muerte-de-los-cetes-como-opcion-de-inversion/"
                >
                  <v-img
                    :max-width="width"
                    :height="height"
                    class="img"
                    lazy-src="../assets/nota5.png"
                    src="../assets/nota5.png"
                  ></v-img
                ></a>
              </div>
            </v-col>
            <v-col class="col_text" cols="12" sm="12" md="12" lg="12" xl="7">
              <p>
                <strong>Muerte de los CETES como opción de inversión.</strong>
                <br /><br />
                Los famosos Cetes o Certificados de la Tesorería, son de los
                activos más conocidos y con los que las personas se inician en
                el mundo de la inversión, pero ¿es productivo invertir en ellos?
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="col_content nota4"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <!-- <h2>
            Oaxaca, espacio de oportunidades: hacia una nueva cultura
            inmobiliaria
          </h2> -->
          <div class="div_logo">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 75px;
              "
            >
              <a target="blank" href="https://www.cbimultiassets.com">
                <img loading="lazy" src="../assets/logo_cbi_blanco.png" />
              </a>
              <div style="width: 100px"></div>

              <a target="blank" href="https://www.atom-inovatec.com/">
                <img
                  style="width: 100px"
                  loading="lazy"
                  src="../assets/ATOM.png"
                />
              </a>
            </div>
          </div>
          <v-row class="row_info">
            <v-col class="col_img" cols="12" sm="12" md="12" lg="12" xl="5">
              <div class="div_img">
                <a target="blank" href="https://fb.watch/88wsi0F5dI/">
                  <v-img
                    loading="lazy"
                    :max-width="width"
                    :height="height"
                    class="img"
                    lazy-src="../assets/MARIO-SALGADO.jpg"
                    src="../assets/MARIO-SALGADO.jpg"
                  ></v-img
                ></a>
              </div>
            </v-col>
            <v-col class="col_text" cols="12" sm="12" md="12" lg="12" xl="7">
              <p>
                <strong
                  >Mario Salgado, el oaxaqueño que ganó el pase al INNOVATE
                  Entrepreneurship Summit en Guatemala </strong
                ><br /><br />
                Impulsado por CBI Multiassets este científico ganó el primer
                lugar a nivel nacional con el ATOM Solar 3, el panel solar
                considerado como el más avanzado del Latinoamérica.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    hideCol: true,
    height: "200",
    width: "350",
  }),
  // created() {
  //   if (window.innerWidth > 1904) {
  //     this.height = "150";
  //     this.width = "250";
  //   } else if (window.innerWidth > 1264 && window.innerWidth < 1904) {
  //     this.height = "250";
  //     this.width = "450";
  //   } else if (window.innerWidth > 960 && window.innerWidth <= 1264) {
  //     this.height = "350";
  //     this.width = "550";
  //     this.hideCol = false;
  //   } else if (window.innerWidth > 600 && window.innerWidth <= 960) {
  //     this.height = "350";
  //     this.width = "500";
  //   } else if (window.innerWidth < 600) {
  //     this.height = "200";
  //     this.width = "300";
  //   }
  // },
  methods: {
    // fnOnResize() {
    //   window.onresize = () => {
    //     if (window.innerWidth > 1904) {
    //       this.height = "150";
    //       this.width = "250";
    //     } else if (window.innerWidth > 1264 && window.innerWidth < 1904) {
    //       this.height = "250";
    //       this.width = "450";
    //     } else if (window.innerWidth > 960 && window.innerWidth <= 1264) {
    //       this.height = "350";
    //       this.width = "550";
    //       this.hideCol = false;
    //     } else if (window.innerWidth > 600 && window.innerWidth <= 960) {
    //       this.height = "350";
    //       this.width = "500";
    //     } else if (window.innerWidth < 600) {
    //       this.height = "200";
    //       this.width = "300";
    //     }
    //   };
    // },
  },
  mounted() {
    window.onresize = () => {
      if (window.innerWidth > 1904) {
        this.height = "200";
        this.width = "350";
      } else if (window.innerWidth > 1264 && window.innerWidth < 1904) {
        this.height = "250";
        this.width = "450";
      } else if (window.innerWidth > 960 && window.innerWidth <= 1264) {
        this.height = "350";
        this.width = "550";
        this.hideCol = false;
      } else if (window.innerWidth > 600 && window.innerWidth <= 960) {
        this.height = "350";
        this.width = "500";
      } else if (window.innerWidth < 600) {
        this.height = "200";
        this.width = "300";
      }
    };
  },
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.fnOnResize);
  // },
};
</script>
<style scoped>
.main {
  position: relative;
  z-index: 1;
  background-color: #85af61;
  width: 100%;
}
.img {
  border-radius: 5px;
  box-shadow: 3px 3px 10px black;
  transition: all 0.2s ease-out;
}
.img:hover {
  box-shadow: 13px 13px 15px rgb(31, 31, 31);
}

/* p {
  text-align: justify;
  color: rgb(0, 0, 0);
} */
h2 {
  text-align: center;
  color: rgb(0, 0, 0);
  height: 100px;
}
h1 {
  width: 100%;
  text-align: center;
  color: #ffffff;
  letter-spacing: 28px;
  margin-bottom: 50px;
}
.text_copy {
  font-size: 0.6em;
}
@media screen and (min-width: 1904px) {
  .div_logo {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 75px;
  }
  .col_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row_info {
    height: 300px;
  }
  .col_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    color: white;
    text-align: justify;
    font-size: 14px;
  }
  strong {
    font-size: 18px;
    text-transform: uppercase;
  }
  .col_img:hover {
    width: 100px;
    cursor: pointer;
  }
  img {
    width: 150px;
  }
  .col_content {
    height: 400px;
  }
  .logos_uea {
    height: 75px;
    z-index: 2;
    position: relative;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  .div_logo {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 75px;
  }
  .logo_doble {
    height: 75px;
  }
  .col_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .row_info {
    height: 300px;
  } */
  .col_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    color: white;
    text-align: justify;
    font-size: 14px;
  }
  strong {
    font-size: 18px;
    text-transform: uppercase;
  }
  .col_img:hover {
    width: 100px;
    cursor: pointer;
  }
  img {
    width: 150px;
  }
  .col_content {
    height: 550px;
  }
  /* .logos_uea {
    height: 115px;
    z-index: 2;
    position: relative;
  } */
  .main {
    position: relative;
    z-index: 1;
    background-color: #85af61;
    width: 100%;
  }
  h1 {
    letter-spacing: 10px;
    font-size: 16px;
  }
  /* .nota2 {
  }
  .nota3 {
    margin-top: 100px;
  } */
  /* .nota4 {
    margin-top: 50px;
    margin-bottom: 100px;
  } */
}
@media screen and (min-width: 960px) and (max-width: 1264px) {
  .div_logo {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 75px;
  }
  .col_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row_info {
    height: 300px;
  }
  .col_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    color: white;
    text-align: justify;
    font-size: 14px;
  }
  strong {
    font-size: 18px;
    text-transform: uppercase;
  }
  .col_img:hover {
    width: 100px;
    cursor: pointer;
  }
  img {
    width: 150px;
  }
  .col_content {
    height: 650px;
  }
  .logos_uea {
    height: 115px;
    z-index: 2;
    position: relative;
  }
  .main {
    position: relative;
    z-index: 1;
    background-color: #85af61;
    width: 100%;
  }
  h1 {
    letter-spacing: 10px;
    font-size: 16px;
  }
  .nota2 {
    margin-top: 50px;
  }
  .nota3 {
    margin-top: 100px;
  }
  .nota4 {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .div_logo {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 75px;
  }
  .col_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row_info {
    height: 300px;
  }
  .col_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    color: white;
    text-align: justify;
    font-size: 14px;
  }
  strong {
    font-size: 18px;
    text-transform: uppercase;
  }
  .col_img:hover {
    width: 100px;
    cursor: pointer;
  }
  img {
    width: 150px;
  }
  .col_content {
    height: 650px;
  }
  .logos_uea {
    height: 115px;
    z-index: 2;
    position: relative;
  }
  .main {
    position: relative;
    z-index: 1;
    background-color: #85af61;
    width: 100%;
  }
  h1 {
    letter-spacing: 10px;
    font-size: 16px;
  }
  .nota2 {
    margin-top: 50px;
  }
  .nota3 {
    margin-top: 100px;
  }
  .nota4 {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .div_logo {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 75px;
  }
  .col_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row_info {
    height: 300px;
  }
  .col_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    color: white;
    text-align: justify;
    font-size: 14px;
  }
  strong {
    font-size: 18px;
    text-transform: uppercase;
  }
  .col_img:hover {
    width: 100px;
    cursor: pointer;
  }
  img {
    width: 150px;
  }
  .col_content {
    height: 550px;
  }
  .logos_uea {
    height: 115px;
    z-index: 2;
    position: relative;
  }
  .main {
    position: relative;
    z-index: 1;
    background-color: #85af61;
    width: 100%;
  }
  h1 {
    letter-spacing: 10px;
    font-size: 16px;
  }
  .col_content {
    /* margin-top: 50px; */
    height: 650px;
  }
}
/* .logo_imparcial{
  margin-top: 40px;
} */
.nota5 {
  margin-bottom: 30px;
}
.row {
  margin: 0px;
}
.logo_imparcial {
  width: 200px;
}
.row {
  margin-bottom: 400px;
}
</style>