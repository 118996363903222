<template>
  <v-app-bar fixed color="white">
    <v-toolbar-title @click="fnTo('/')"
      ><v-img lazy-src="../assets/logoM.png" src="../assets/logoM.png"></v-img
    ></v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- <v-btn text class="btn" @click="fnTo('/')"> Home </v-btn> -->
    <!--START BAR DESKTOP-->
    <div class="button">
      <v-btn text class="btn" @click="fnTo('/conocenos')"> Conócenos </v-btn>
      <v-menu rounded="b-xl" open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="btn" text v-bind="attrs" v-on="on"> Atención </v-btn>
        </template>

        <v-list>
          <v-list-item
            class="item"
            @click="fnTo('diagnostico_de_inversionista')"
          >
            <v-list-item-title>Diagnóstico de inversiones</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu rounded="b-xl" open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="btn" text v-bind="attrs" v-on="on"> Eventos </v-btn>
        </template>

        <v-list>
          <v-list-item
            class="item"
            @click="fnTo(item)"
            v-for="[item, index] in eventos"
            :key="index"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text class="btn" @click="scrollTo('notas')"> Prensa </v-btn>

      <v-menu rounded="b-xl" open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="btn" text v-bind="attrs" v-on="on">
            Account Manager
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            class="item"
            href="https://contratos.cbimultiassets.com"
            target="_blank"
          >
            <v-list-item-title>Contratos</v-list-item-title>
          </v-list-item>
          <v-list-item
            class="item"
            href="https://www.facebook.com/groups/1080891862355635/?ref=share"
            target="_blank"
          >
            <v-list-item-title>Capacitación Account Manager</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <v-btn
        color="#f6b81d"
        dark
        class="btn"
        href="https://pagos.magueytoken.com/"
        target="_blank"
      >
        Comienza a invertir
      </v-btn> -->
    </div>
    <!--END BAR DESKTOP-->
  </v-app-bar>
</template>
<script>
export default {
  data: () => ({
    eventos: [
      ["Webinar", "/"],
      ["Mezcalizate", "/mezcalizate"],
      ["Experiencias", "/experiencias"],
    ],
  }),
  methods: {
    scrollTo(elementId) {
      const section = this.$el.querySelector(`.${elementId}`);

      if (this.$route.path != "/") {
        this.$router.push("/");
        setTimeout(() => {
          const section2 = this.$el.querySelector(`.${elementId}`);

          section2.scrollIntoView({ block: "end", inline: "end" });
        }, 100);
      } else {
        section.scrollIntoView({ block: "end", inline: "end" });
      }
      this.drawer = false;
    },
       fnTo(ruta) {
      if (this.$route.path != ruta) {
        this.$router.push(ruta);
      }
    },
  },
};
</script>