<template>
  <div class="main">
    <div class="container">
      <h1 class="mx-auto">ALIADOS COMERCIALES</h1>
      <carousel-3d
        :autoplay="true"
        :autoplay-timeout="2000"
        :controls-visible="false"
        :perspective="0"
        :display="3"
        :space="500"
        :controls-width="900"
      >
        <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="0"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608660/Mesa_de_trabajo_1_qwqpa9.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608660/Mesa_de_trabajo_1_qwqpa9.png"
            alt=""
          />
        </slide>
        <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="1"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_2_dladot.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_2_dladot.png"
            alt=""
          />
        </slide>
        <!-- <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="2"
        >
          <img
            style="width: 90%"
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_3_tesk3o.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_3_tesk3o.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="3"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_4_l5v4jw.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_4_l5v4jw.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="4"
        >
          <img
          style="margin-top:-10%"
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_5_yp8jnh.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_5_yp8jnh.png"
            alt=""
          /> </slide> -->
          <!-- <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="5"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_6_ycoyw8.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608679/Mesa_de_trabajo_6_ycoyw8.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="6"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608660/Mesa_de_trabajo_7_wngzru.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630608660/Mesa_de_trabajo_7_wngzru.png"
            alt=""
          />
        </slide> -->
        <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="2"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_8_to17lm.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_8_to17lm.png"
            alt=""
          />
        </slide>
        <slide
          :style="`background-color: ${color}`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="3"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_9_mgguok.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_9_mgguok.png"
            alt=""
          />
        </slide>
        <!-- <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="9"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_10_anpfys.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_10_anpfys.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="10"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_11_rsr5nu.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607108/Mesa_de_trabajo_11_rsr5nu.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="11"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_12_ez8hlt.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_12_ez8hlt.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="12"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_13_nfqwnf.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_13_nfqwnf.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="13"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_14_ymxvle.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_14_ymxvle.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="14"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_15_hn0yly.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_15_hn0yly.png"
            alt=""
          />
        </slide> -->
        <!-- <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="15"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_16_z6rkbn.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_16_z6rkbn.png"
            alt=""
          />
        </slide> -->
        <slide
          :style="`background-color: ${color};`"
          class="slide"
          space="450"
          width="600"
          height="400"
          :index="4"
        >
          <img
            lazy-src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_17_g0dnrf.png"
            src="https://res.cloudinary.com/duymuyggi/image/upload/v1630607109/Mesa_de_trabajo_17_g0dnrf.png"
            alt=""
          />
        </slide>
      </carousel-3d>
      <p class="p_text">
        Las alianzas comerciales con las presentes marcas pueden incluir el uso
        de restaurantes, palenques, material audiovisual, eventos, productos,
        redes sociales o canales de distribución de productos. Las presentes
        marcas no tiene ninguna responsabilidad legal o financiera con la oferta
        privada de valores promovida bajo el nombre ROOTSTOKEN.
      </p>
    </div>
    <svg
      id="waves_id"
      class="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(0,0,0,0.7)" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(0,0,0,0.5)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(0,0,0,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#000000" />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: String,
  },
  data() {
    return {
      items: [
        {
          src: "https://res.cloudinary.com/duymuyggi/image/upload/v1630608660/Mesa_de_trabajo_1_qwqpa9.png",
        },
        {
          src: "../assets/a1id.png",
        },
        {
          src: "../assets/a1ertha.pnf",
        },
        {
          src: "../assets/a1na-vida.png",
        },
        {
          src: "../assets/a1so.png",
        },
        {
          src: "https://inversiones.magueytoken.com/wp-content/uploads/2021/05/atila_Mesa-de-trabajo-1.png",
        },
        {
          src: "https://inversiones.magueytoken.com/wp-content/uploads/2021/03/MEZCAL-BUZZ.png",
        },
        {
          src: "https://inversiones.magueytoken.com/wp-content/uploads/2021/03/ANDADOR.png",
        },
        {
          src: "https://inversiones.magueytoken.com/wp-content/uploads/2021/05/oRANGE.png",
        },
      ],
    };
  },
};
</script>
<style scoped>
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.slide {
  border-radius: 10px;
  border: 0px;
  padding: 10%;
}
h1 {
  letter-spacing: 28px;
  color: white;
  text-align: center;
}
@media screen and (min-width: 1904px) and (min-height: 900px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: -2em;
  }
}
@media screen and (min-width: 1904px) and (min-height: 800px) and (max-height: 900px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 60vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: -2em;
  }
}
@media screen and (min-width: 1904px) and (min-height: 700px) and (max-height: 800px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 70vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: -2em;
  }
}
@media screen and (min-width: 1904px) and (min-height: 600px) and (max-height: 700px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 75vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: -2em;
  }
}
@media screen and (min-width: 1904px) and (min-height: 500px) and (max-height: 600px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 90vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 40vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: -2em;
  }
}

@media screen and (min-width: 1264px) and (max-height: 1904px) and (min-height: 900px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 60vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: 0;
    font-size: 0.9em;
  }
}
@media screen and (min-width: 1264px) and (max-height: 1904px) and (min-height: 800px) and (max-height: 900px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 70vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: 0;
    font-size: 0.9em;
  }
}
@media screen and (min-width: 1264px) and (max-height: 1904px) and (min-height: 700px) and (max-height: 800px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 80vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 40vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: 0;
    font-size: 0.9em;
  }
}
@media screen and (min-width: 1264px) and (max-height: 1904px) and (min-height: 600px) and (max-height: 700px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 90vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 40vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: 0;
    font-size: 0.9em;
  }
}
@media screen and (min-width: 1264px) and (max-height: 1904px) and (min-height: 500px) and (max-height: 600px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 90vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: 0;
    font-size: 0.9em;
  }
}
@media screen and (min-width: 1264px) and (max-height: 1904px) and (max-height: 500px) {
  .container {
    /* width: 50%; */
    top: 15%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 90vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    color: white;
    text-align: center;
    margin-top: 0;
    font-size: 0.9em;
  }
}

@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 900px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 35vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  @media screen and (min-height: 100px) {
    .container {
      top: 15%;
    }
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 800px) and (max-height: 900px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 40vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 700px) and (max-height: 800px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 55vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 600px) and (max-height: 700px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 60vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 500px) and (max-height: 600px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 70vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (max-height: 500px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 75vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 45vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 900px) {
  .container {
    /* width: 50%; */
    top: 10%;
    position: relative;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .main {
    width: 100%;
    height: 70vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 35vh;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.7em;
  }
  @media screen and (min-height: 100px) {
    .container {
      top: 20%;
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 800px) and (max-height: 900px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 45vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 40vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 700px) and (max-height: 800px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 600px) and (max-height: 700px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 35vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 500px) and (max-height: 700px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 75vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (max-height: 500px) {
  .container {
    /* width: 50%; */
    top: 0%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 85vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    color: white;
    font-size: 0.7em;
    margin-top: 0;
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .p_text {
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.7em;
  }
}

@media screen and (max-width: 600px) and (min-height: 900px) {
  .container {
    /* width: 50%; */
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 30vh;
  }
  .p_text {
    margin-top: 0%;
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.8em;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) and (max-height: 900px) {
  .container {
    /* width: 50%; */
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  /* .main {
    margin-bottom: 40%;
  } */
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    /* max-height: 150px; */
    z-index: 2;
    bottom: 0;
  }
  .main {
    width: 100%;
    height: 50vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    margin-top: 0%;
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.65em;
  }
}
@media screen and (max-width: 600px) and (min-height: 700px) and (max-height: 800px) {
  .container {
    /* width: 50%; */
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  /* .main {
    margin-bottom: 40%;
  } */
  .waves {
    position: absolute;
    width: 100%;
    height: 4vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .main {
    width: 100%;
    height: 55vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    margin-top: 5%;
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.6em;
  }
}
@media screen and (max-width: 600px) and (min-height: 600px) and (max-height: 700px) {
  .container {
    /* width: 50%; */
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  /* .main {
    margin-bottom: 40%;
  } */
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .main {
    width: 100%;
    height: 65vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    margin-top: 5%;
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.6em;
  }
}
@media screen and (max-width: 600px) and (min-height: 500px) and (max-height: 600px) {
  .container {
    /* width: 50%; */
  }
  /* .main {
    margin-bottom: 40%;
  } */
  .waves {
    position: absolute;
    width: 100%;
    height: 3vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .main {
    width: 100%;
    height: 80vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 60vh;
  }
  .p_text {
    margin-top: -15%;
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.6em;
  }
  h1{
    font-size: .8em;
    letter-spacing: 10px;
  }
  .slide{
    margin-top: -80px;
  }
}
@media screen and (max-width: 600px) and (max-height: 500px) {
  .container {
    /* width: 50%; */
  }
  /* .main {
    margin-bottom: 40%;
  } */
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .main {
    width: 100%;
    height: 80vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    margin-top: -15%;
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.5em;
  }
}
@media screen and (max-width: 600px) and (max-height: 500px) {
  .container {
    /* width: 50%; */
  }
  h1 {
    letter-spacing: 18px;
    color: white;
    text-align: center;
    font-size: 1em;
    margin-bottom: -70px;
  }
  /* .main {
    margin-bottom: 40%;
  } */
  .waves {
    position: absolute;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    max-height: 150px;
    z-index: 2;
    bottom: 0;
  }
  .main {
    width: 100%;
    height: 80vh;
    position: relative;
    background-color: #85af61;
    margin-bottom: 50vh;
  }
  .p_text {
    margin-top: -15%;
    padding: 0 5% 0 5%;
    text-align: justify;
    color: white;
    font-size: 0.5em;
  }
}
</style>