<template>
  <div>
    <Header />
    <Body />
    <Pasos />
    <Proceso />
    <Investing />
    <!-- <Notas  /> -->
    <Form :formId="formId" />
    <Aliados :color="color" />

    <!-- <Retorno/> -->
    <div id="bubbles">
      <a href="https://www.facebook.com/magueytoken/" target="_blank">
        <div
          @mouseover="fnOverFace"
          @mouseleave="fnLeaveFace"
          style="z-index: 5000"
          class="bubble x1 magnetic"
        >
          <v-avatar class="magnet-button my-button" :size="sizeFace">
            <img src="../assets/facebook.png" alt="John" />
          </v-avatar>
        </div>
      </a>
      <a href="https://www.instagram.com/magueytoken/?hl=e" target="_blank">
        <div
          @mouseover="fnOverInsta"
          @mouseleave="fnLeaveInsta"
          style="z-index: 5000"
          class="bubble x2 magnetic"
        >
          <v-avatar class="magnet-button my-button" :size="sizeInsta">
            <img src="../assets/instagram.png" alt="John" />
          </v-avatar>
        </div>
      </a>
      <a
        href="https://www.youtube.com/channel/UCrdsvFCS-dxd262mt-NqA9Q/videos"
        target="_blank"
      >
        <div
          @mouseover="fnOverTube"
          @mouseleave="fnLeaveTube"
          style="z-index: 5000"
          class="bubble x3 magnetic"
        >
          <v-avatar class="magnet-button my-button" :size="sizeTube">
            <img src="../assets/youtube.png" alt="John" />
          </v-avatar>
        </div>
      </a>
      <a
        href="https://www.linkedin.com/showcase/magueytoken/?originalSubdomain=mx"
        target="_blank"
      >
        <div
          @mouseover="fnOverLink"
          @mouseleave="fnLeaveLink"
          style="z-index: 5000"
          class="bubble x4 magnetic"
        >
          <v-avatar class="magnet-button my-button" :size="sizeLink">
            <img src="../assets/linkedin.png" alt="John" />
          </v-avatar>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header2.vue";
import Body from "@/components/Body.vue";
import Pasos from "@/components/Pasos2.vue";
import CursorB from "@/components/CursorB.vue";
import Retorno from "@/components/Video2.vue";
import Proceso from "@/components/Proceso2.vue";
import Investing from "@/components/Investing.vue";
import Notas from "@/components/Notas2.vue";
import Form from "@/components/Form.vue";
import Aliados from "@/components/Aliados.vue";

export default {
  components: {
    Header,
    Body,
    Pasos,
    CursorB,
    Retorno,
    Proceso,
    Investing,
    Notas,
    Form,
    Aliados,
  },
  data: () => ({
    sizeFace: 60,
    sizeInsta: 60,
    sizeTube: 60,
    sizeLink: 60,
    formId: "99adb1df-57b6-4979-9b59-783354105b99",
    color: "transparent",
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    fnOverFace() {
      this.sizeFace = 80;
    },
    fnLeaveFace() {
      this.sizeFace = 60;
    },
    fnOverInsta() {
      this.sizeInsta = 80;
    },
    fnLeaveInsta() {
      this.sizeInsta = 60;
    },
    fnOverTube() {
      this.sizeTube = 80;
    },
    fnLeaveTube() {
      this.sizeTube = 60;
    },
    fnOverLink() {
      this.sizeLink = 80;
    },
    fnLeaveLink() {
      this.sizeLink = 60;
    },
  },
};
</script>
<style scoped>
/* #bubbles {
  cursor: none;
} */
.bubble {
  background: #4a7aff;
  border-radius: 200px;
  -moz-border-radius: 200px;
  -webkit-border-radius: 200px;
  position: absolute;
  z-index: 700;
}
.my-button {
  transition: all 0.1s ease;
}
.x1 {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
  left: 10%;
  background: #4a7aff;
  -webkit-animation: moveclouds 55s linear infinite,
    sideWays 4s ease-in infinite alternate;
  -moz-animation: moveclouds 55s linear infinite,
    sideWays 4s ease-in infinite alternate;
  -o-animation: moveclouds 55s linear infinite,
    sideWays 4s ease-in infinite alternate;
}

.x2 {
  left: 30%;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);

  -webkit-animation: moveclouds 55s linear infinite,
    sideWays 5s ease-in-out infinite alternate;
  -moz-animation: moveclouds 55s linear infinite,
    sideWays 5s ease-in-out infinite alternate;
  -o-animation: moveclouds 55s linear infinite,
    sideWays 5s ease-in-out infinite alternate;
}
.x3 {
  left: 70%;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);

  -webkit-animation: moveclouds 50s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: moveclouds 50s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -o-animation: moveclouds 50s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.x4 {
  left: 90%;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-animation: moveclouds 60s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: moveclouds 60s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -o-animation: moveclouds 60s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
}
.x5 {
  left: 150px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);

  -webkit-animation: moveclouds 7s linear infinite,
    sideWays 1s ease-in-out infinite alternate;
  -moz-animation: moveclouds 7s linear infinite,
    sideWays 1s ease-in-out infinite alternate;
  -o-animation: moveclouds 7s linear infinite,
    sideWays 1s ease-in-out infinite alternate;
}
@-webkit-keyframes moveclouds {
  0% {
    top: 110%;
  }
  100% {
    top: 0px;
    opacity: 0.8;
  }
}

@-webkit-keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

@-moz-keyframes moveclouds {
  0% {
    top: 500px;
  }

  100% {
    top: -500px;
  }
}

@-moz-keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}
@-o-keyframes moveclouds {
  0% {
    top: 500px;
  }
  100% {
    top: -500px;
  }
}

@-o-keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}
@media screen and (max-width: 600px) {
  .x1 {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
    left: 10%;
    background: #4a7aff;
    -webkit-animation: moveclouds 35s linear infinite,
      sideWays 14s ease-in infinite alternate;
    -moz-animation: moveclouds 35s linear infinite,
      sideWays 14s ease-in infinite alternate;
    -o-animation: moveclouds 35s linear infinite,
      sideWays 14s ease-in infinite alternate;
  }

  .x2 {
    left: 30%;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);

    -webkit-animation: moveclouds 40s linear infinite,
      sideWays 15s ease-in-out infinite alternate;
    -moz-animation: moveclouds 40s linear infinite,
      sideWays 15s ease-in-out infinite alternate;
    -o-animation: moveclouds 40s linear infinite,
      sideWays 15s ease-in-out infinite alternate;
  }
  .x3 {
    left: 55%;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);

    -webkit-animation: moveclouds 27s linear infinite,
      sideWays 14s ease-in-out infinite alternate;
    -moz-animation: moveclouds 27s linear infinite,
      sideWays 14s ease-in-out infinite alternate;
    -o-animation: moveclouds 27s linear infinite,
      sideWays 14s ease-in-out infinite alternate;
  }
  .x4 {
    left: 70%;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-animation: moveclouds 33s linear infinite,
      sideWays 12s ease-in-out infinite alternate;
    -moz-animation: moveclouds 33s linear infinite,
      sideWays 12s ease-in-out infinite alternate;
    -o-animation: moveclouds 33s linear infinite,
      sideWays 12s ease-in-out infinite alternate;
  }
}
a {
  cursor: none;
}
</style>