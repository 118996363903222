<template>
  <div class="main">
    <div
      data-aos="zoom-in"
      data-aos-duration="500"
      data-aos-delay="0"
      class="mx-auto div_form"
    >
      <div class="main2">
        <div class="container" id="hubspotForm"></div>
      </div>
    </div>
    <svg
      id="waves_id"
      class="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g class="parallax">
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="0"
          fill="rgba(133,175,97,0.7)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="3"
          fill="rgba(133,175,97,0.5)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="5"
          fill="rgba(133,175,97,0.3)"
        />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#85AF61" />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    formId: String,
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "8118076",
          formId: this.formId,
          target: "#hubspotForm",
          region: "na1",
        });
      }
    });
  },
};
</script>
<style scoped>
.div_form {
  width: 50%;
}

.container {
  /* top: 20%; */
  position: absolute;
}
.waves {
  position: absolute;
  width: 100%;
  height: 3vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 00px;
  max-height: 150px;
  z-index: 2;
  bottom: 0;
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@media screen and (min-width: 1904px) {
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 60vh;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 110vh;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) and (min-height: 700px) {
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 60vh;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) {
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 110vh;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height:700px) {
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 60vh;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 110vh;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height:700px)  {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 70vh;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (max-height:700px) {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 110vh;
  }
}
@media screen and (max-width: 600px) {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 130vh;
  }
}
@media screen and (max-width: 600px) and (min-height:800px) {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
  }
}
@media screen and (max-width: 600px) and (min-height:700px) and (max-height:800px) {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
  }
}
@media screen and (max-width: 600px) and (min-height:600px) and (max-height:700px) {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 125vh;
  }
}
@media screen and (max-width: 600px) and (min-height:500px) and (max-height:600px) {
  .div_form {
    width: 90%;
  }
  .container {
    top: 0%;
    position: absolute;
  }
  .main {
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 135vh;
  }
}
</style>