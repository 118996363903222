import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        audio1: true,
        audio2: true,
        audio3: true,
    },
    getters: {
        getter_audio1(state) {
            return state.audio1
        },
        getter_audio2(state) {
            return state.audio2
        },
        getter_audio3(state) {
            return state.audio3
        }
    },
    mutations: {
        mutation_audio1(state, data) {
            state.audio1 = data

            var video2 = document.getElementById("myVideo2")
            var video3 = document.getElementById("myVideo3")
            video2.muted = true
            video3.muted = true
            state.audio2 = true
            state.audio3 = true
        },
        mutation_audio2(state, data) {
            state.audio2 = data

            var video1 = document.getElementById("myVideo")
            var video3 = document.getElementById("myVideo3")
            video1.muted = true
            video3.muted = true
            state.audio1 = true
            state.audio3 = true
        },
        mutation_audio3(state, data) {
            state.audio3 = data

            var video1 = document.getElementById("myVideo")
            var video2 = document.getElementById("myVideo2")
            video1.muted = true
            video2.muted = true
            state.audio1 = true
            state.audio2 = true
        },
    },
    actions: {
        action_audio1(context, data) {
            context.commit('mutation_audio1', data)
        },
        action_audio2(context, data) {
            context.commit('mutation_audio2', data)
        },
        action_audio3(context, data) {
            context.commit('mutation_audio3', data)
        }
    },
    modules: {}
})