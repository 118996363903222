<template>
  <div class="main">
    <div
      class=""
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <h1>CONOCE NUESTRAS INVESTING EXPERIENCE</h1>
    </div>
    <br /><br />
    <div @click="fnPlay" class="box-mov">
      <div class="mov-des">
        <div id="hover" class="des-text">
          <h3
            @mouseover="fnTexth3"
            class="textH3 text-center"
            style="z-index: 1; cursor: pointer"
          >
            Activa tu experiencia <br />aquí
          </h3>
        </div>
      </div>
      <div class="mov-item">
        <video id="myVideo" autoplay muted width="90%" loop>
          <source src="../assets/experiencia.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <v-btn fab class="btn_sound" icon x-large
          ><v-icon v-if="volume" class="icon_sound" color="#85af61" x-large
            >mdi-volume-high</v-icon
          ></v-btn
        >
        <v-btn v-if="!volume" fab class="btn_sound" icon x-large
          ><v-icon class="icon_sound" color="#85af61" x-large
            >mdi-volume-off</v-icon
          ></v-btn
        >
        <canvas id="cnv"></canvas>
      </div>
    </div>
    <svg
      id="waves_id"
      class="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g class="parallax">
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="0"
          fill="rgba(255,255,255,.7)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="3"
          fill="rgba(255,255,255,.5)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="5"
          fill="rgba(255,255,255,.3)"
        />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
  </div>
</template>
<script>
import gsap from "gsap";
export default {
  data: () => ({
    volume: true,
  }),
  mounted() {
    let vid = document.getElementById("myVideo");
    let canvas = document.getElementById("cnv");
    let ctx = canvas.getContext("2d");
    // damos al canvas la misma altura y anchura que al video
    let cw = (canvas.width = 1575.89);
    let ch = (canvas.height = 888);
    ctx.drawImage(vid, 0, 0, cw, ch);

    vid.addEventListener(
      "play", //si el video se esta reproduciendo
      () => {
        // dibujelo en el canvas
        pintarVideo(vid, ctx, cw, ch);
      },
      false
    );

    function pintarVideo() {
      requestAnimationFrame(pintarVideo);
      // si el video esta parado o se ha acabado no hagas nada y sal de aquí
      if (vid.paused || vid.ended) {
        return;
      }
      // pinta el video en el canvas
      ctx.drawImage(vid, 0, 0, cw, ch);
    }
    console.log(vid.paused);
  },
  methods: {
    fnTexth3() {
      TweenMax.to(".textH3", 0.4, { scale: 1.5, ease: Bounce.easeOut });
      TweenMax.to(".textH3", 0.2, { scale: 1, delay: 0.4 });
    },
    fnPlay() {
      var video = document.getElementById("myVideo");
      var hover = document.getElementById("hover");

      if (video.muted) {
        video.muted = false;
        hover.style.display = "none";
      } else {
        video.muted = true;
        hover.style.display = "flex";
      }
    },
  },
};
</script>
<style scoped>
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
body {
  background-color: #85af61;
}

.box-mov {
  position: relative;
  transition: all 0.7s ease;
}
.box-mov .mov-des {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 1;
}
.box-mov .mov-des .des-text {
  background-color: rgba(0, 0, 0, 0.493);
  padding: 1rem;
  color: #ffffff;
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  border-radius: 10px;
}
.box-mov .mov-des .des-text p {
  margin: 0;
  text-transform: uppercase;
}
.box-mov .mov-des img {
  height: 100%;
}
.box-mov .mov-des.hide {
  opacity: 0;
  visibility: hidden;
}
.mov-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
video {
  position: absolute;
  width: 0;
  height: 0;
}
#cnv {
  border-radius: 10px;
  width: 90%;
  margin-bottom: 5%;
  background: url(../assets/portada_prev.jpg),
    linear-gradient(110deg, black 0%, #000000 100%);
  background-size: cover;
  background-repeat: no-repeat;
}
.btn_sound {
  position: absolute;
  top: 1.5%;
  left: 7%;
  z-index: 10;
}
h3 {
  color: #85af61;
  text-shadow: 10px 5px 5px black;
}
.main {
  background-color: #85af61;
  z-index: 2;
}
h1 {
  color: white;
  letter-spacing: 20px;
}
@media screen and (min-width: 1904px) {
  .div_span {
    font-size: 2em;
    color: #fff;
  }
  .waves {
    position: relative;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  h1 {
    margin-top: 30px;
    text-align: center;
    font-size: 2em;
    letter-spacing: 5px;
  }
  .div_span {
    font-size: 2em;
    color: #fff;
  }
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) {
  h1 {
    margin-top: 30px;
    text-align: center;
    font-size: 1.5em;
    letter-spacing: 5px;
  }
  .div_span {
    font-size: 1.5em;
    color: #fff;
  }
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  h1 {
    margin-top: 30px;
    text-align: center;
    font-size: 1em;
    letter-spacing: 5px;
  }
  .div_span {
    font-size: 1.3em;
    color: #fff;
  }
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
}
@media screen and (max-width: 600px) {
  .div_span {
    font-size: 1em;
    color: #fff;
  }
  .waves {
    position: relative;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/

    max-height: 150px;
    z-index: 2;
  }
  .box-mov .mov-des .des-text {
    background-color: rgba(0, 0, 0, 0.493);
    padding: 1rem;
    color: #ffffff;
    font-size: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    border-radius: 0px;
    width: 100%;
    z-index: 3;
  }
  video,
  #cnv {
    border-radius: 0px;
    margin-bottom: 5%;
    width: 100%;
    z-index: 2;
  }
  .main {
    width: 100%;
  }
  h1 {
    margin-top: 30px;
    text-align: center;
    font-size: 0.8em;
    letter-spacing: 5px;
  }
}
</style>