var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"fixed":"","color":"white"}},[_c('v-toolbar-title',{on:{"click":function($event){return _vm.fnTo('/')}}},[_c('v-img',{attrs:{"lazy-src":require("../assets/logoM.png"),"src":require("../assets/logoM.png")}})],1),_c('v-spacer'),_c('div',{staticClass:"button"},[_c('v-btn',{staticClass:"btn",attrs:{"text":""},on:{"click":function($event){return _vm.fnTo('/conocenos')}}},[_vm._v(" Conócenos ")]),_c('v-menu',{attrs:{"rounded":"b-xl","open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Atención ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"item",on:{"click":function($event){return _vm.fnTo('diagnostico_de_inversionista')}}},[_c('v-list-item-title',[_vm._v("Diagnóstico de inversiones")])],1)],1)],1),_c('v-menu',{attrs:{"rounded":"b-xl","open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Eventos ")])]}}])},[_c('v-list',_vm._l((_vm.eventos),function(ref){
var item = ref[0];
var index = ref[1];
return _c('v-list-item',{key:index,staticClass:"item",on:{"click":function($event){return _vm.fnTo(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-btn',{staticClass:"btn",attrs:{"text":""},on:{"click":function($event){return _vm.scrollTo('notas')}}},[_vm._v(" Prensa ")]),_c('v-menu',{attrs:{"rounded":"b-xl","open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Account Manager ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"item",attrs:{"href":"https://contratos.cbimultiassets.com","target":"_blank"}},[_c('v-list-item-title',[_vm._v("Contratos")])],1),_c('v-list-item',{staticClass:"item",attrs:{"href":"https://www.facebook.com/groups/1080891862355635/?ref=share","target":"_blank"}},[_c('v-list-item-title',[_vm._v("Capacitación Account Manager")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }