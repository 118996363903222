<template>
  <div>
    <div class="header">
      <!-- <div class="circle" ref="circle"></div>
      <div class="circle_yellow" ref="circle"></div> -->
      <div class="carousel">
        <carousel-3d
          :width="ancho"
          :height="alto"
          :autoplay="true"
          :autoplay-timeout="3000"
          :display="1"
          :controls-visible="true"
        >
          <slide class="slide" :index="0">
            <v-img
              gradient="to top right, rgba(255,190,51,.33), rgba(25,32,72,.7)"
              class="img"
              src="../assets/bp1.jpg"
              lazy-src="../assets/bp1.jpg"
            >
              <p>
                Estimaciones del despacho Seale &amp; Associados proyectaban que
                en 2017 la categorías de snacks saludables cerraría con un valor
                de mercado de 32,503 millones de pesos en México
              </p>
            </v-img>
          </slide>
          <slide class="slide" :index="1">
            <v-img
              gradient="to top right, rgba(255,190,51,.33), rgba(25,32,72,.7)"
              class="img"
              src="../assets/bp2.jpg"
              lazy-src="../assets/bp2.jpg"
            >
              <p>
                Los llamados “superalimentos” y los ingredientes 100% orgánicos
                van a ser lo más demandado en alimentación durante el año 2021
              </p>
            </v-img>
          </slide>
          <slide class="slide" :index="2">
            <v-img
              gradient="to top right, rgba(255,190,51,.33), rgba(25,32,72,.7)"
              class="img"
              src="../assets/bp3.jpg"
              lazy-src="../assets/bp3.jpg"
            >
              <p>
                Entre el 8% y el 26% del gasto habitual de los mexicanos se
                destina a alimentos catalogados como saludables. (Wellness), así
                lo arroja el último reporte de consumo en el rubro de alimentos
                de Nielsen
              </p>
            </v-img>
          </slide>
          <slide class="slide" :index="3">
            <v-img
              gradient="to top right, rgba(255,190,51,.33), rgba(25,32,72,.7)"
              class="img"
              src="../assets/bp4.jpg"
              lazy-src="../assets/bp4.jpg"
            >
              <p>
                7 de cada 10 mexicanos apuestan por una alimentación saludable
              </p>
            </v-img>
          </slide>
        </carousel-3d>
      </div>
      <svg
        id="waves_id"
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(27,72,89,0.7)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(27,72,89,0.5)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(27,72,89,0.3)"
          />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#1b4859" />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(CSSPlugin, ScrollTrigger);
// gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      ancho: 1600,
      alto: 1000,
    };
  },
  methods: {
    fnCircle() {
      ScrollTrigger.defaults({
        toggleActions: "restart pause resume none",
        markers: false,
      });
      gsap.to(".circle", 2, {
        scrollTrigger: { trigger: ".box" },
        ease: "elastic.out(1,0.3)",
        y: 100,
      });
      gsap.to(".circle_yellow", 2, {
        scrollTrigger: { trigger: ".box" },
        ease: "elastic.out(1,0.3)",
        y: 100,
      });
      // gsap.from(".circle", {
      //   duration: 2,
      //   ease: "elastic.out(1,0.3)",
      //   y: -500,
      // });
    },
  },
  mounted() {
    this.fnCircle();
  },
  // created() {
  //   if (window.innerWidth > 600 && window.innerWidth <= 960) {
  //     this.ancho = 1500;
  //     this.alto = 750;
  //   } else if (window.innerWidth <= 600) {
  //     this.ancho = 400;
  //     this.alto = 200;
  //   }
  //   window.onresize = () => {
  //     if (window.innerWidth <= 600) {
  //       this.ancho = 300;
  //       this.alto = 200;
  //       console.log(window.innerWidth);
  //     } else {
  //       this.ancho = 1500;
  //       this.alto = 750;
  //     }
  //   };
  // },
};
</script>
<style scoped>
/* .header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    rgb(246, 29, 29),
    rgba(0, 0, 0, 0.9),
    rgba(172, 132, 22, 0)
  );
  z-index: -1;
} */

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@media screen and (min-width: 1904px) {
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .header {
    position: relative;
    /* background: linear-gradient(#85af61, #000000 100%); */
    background-color: #85af61;
    color: white;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  p {
    color: white;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
  }
  .carousel {
    z-index: 2;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5%;
    position: absolute;
    padding: 0 5% 0 5%;
    transition: transform 0.2s;
  }
  .carousel:hover {
    transform: scale(1.05);
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .header {
    position: relative;
    background-color: #85af61;
    color: white;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  p {
    color: white;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
  }
  .carousel {
    z-index: 2;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5%;
    position: absolute;
    padding: 0 5% 0 5%;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) {
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .header {
    position: relative;
    background-color: #85af61;
    color: white;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  p {
    color: white;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
  }
  .carousel {
    z-index: 2;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5%;
    position: absolute;
    padding: 0 5% 0 5%;
  }
}
/*Shrinking for mobile*/
@media screen and (min-width: 600px) and (max-width: 960px) {
  .waves {
    position: relative;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
    z-index: 2;
  }
  .header {
    position: relative;
    background-color: #85af61;
    color: white;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 50vh;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  p {
    color: white;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
  }
  .carousel {
    z-index: 2;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -20%;
    position: absolute;
    padding: 0 5% 0 5%;
  }
}
@media (max-width: 600px) {
  .waves {
    position: relative;
    width: 100%;
    height: 7vh;
    margin-bottom: -7px; /*Fix for safari gap*/

    max-height: 150px;
    z-index: 2;
  }
  .header {
    position: relative;
    background-color: #85af61;
    color: white;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 40vh;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
  }
  p {
    color: white;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
  }
  .waves {
    height: 7vh;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 20px;
  }
  .carousel {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5%;
    position: absolute;
    padding: 0 5% 0 5%;
  }
}
#waves_id {
  bottom: 1px;
  position: absolute;
}
.line-yellow1 {
  width: 15px;
  height: 100%;
  background: #f6b81d;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
}
.line-yellow2 {
  width: 15px;
  height: 100%;
  background: #f6b81d;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
}
.img {
  height: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.slide {
  border: 0;
  border-radius: 10px;
}
.circle_yellow {
  width: 100px;
  height: 100px;
  background-color: #f6b81d;
  border-radius: 100%;
  left: -50px;
  bottom: 350px;
  position: absolute;
  z-index: 3;
}
.circle {
  width: 200px;
  height: 200px;
  background-color: black;
  border-radius: 100%;
  left: -100px;
  bottom: 350px;
  position: absolute;
  z-index: 3;
}
.next[data-v-05517ad0],
.prev[data-v-05517ad0] {
  color: red;
}
</style>