import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home2.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/diagnostico_de_inversionista',
        name: 'Diagnostico',
        // route level code-splitting
        // this generates a separate chunk (diagnostico_de_inversionista.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "diagnostico_de_inversionista" */ '../views/Diagnostico.vue')
    },
    {
        path: '/experiencias',
        name: 'Experiencias',
        // route level code-splitting
        // this generates a separate chunk (experiencias.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "experiencias" */ '../views/Experiencias.vue')
    },
    {
        path: '/mezcalizate',
        name: 'Mezcalizate',
        // route level code-splitting
        // this generates a separate chunk (mezcalizate.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "mezcalizate" */ '../views/Mezcalizate.vue')
    },
    {
        path: '/conocenos',
        name: 'Conocenos',
        // route level code-splitting
        // this generates a separate chunk (conocenos.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "conocenos" */ '../views/Conocenos.vue')
    },
    {
        path: '/prensa',
        name: 'Notas',
        // route level code-splitting
        // this generates a separate chunk (prensa.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "prensa" */ '../views/Notas.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router