<template>
  <div>
    <div class="cursor"></div>
    <div class="cursorBorder"></div>
  </div>
</template>
<script>
$(document).mousemove(function (e) {
  $(".cursor")
    .eq(0)
    .css({
      left: e.clientX + $(window).scrollLeft(),
      top: e.clientY + $(window).scrollTop(),
    });
  setTimeout(function () {
    $(".cursorBorder").css({
      left: e.clientX + $(window).scrollLeft(),
      top: e.clientY + $(window).scrollTop(),
    });
  }, 25);
});
export default {};
</script>
<style scoped>
@media screen and (max-width:600px) {
  .cursor,.cursorBorder{
    display: none;
  }
}
html,
* {
  margin: 0;
  padding: 0;
}
body {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://picsum.photos/2200/1900/?random) center center;
  background-size: cover;
  height: 100vh;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
}
h1 {
  color: rgb(0, 0, 0);
  font-family: futura-pt, sans-serif;
  letter-spacing: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
.cursor {
  /* position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%); */
  width: 15px;
  height: 15px;
  background-color: #f6b81d;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9002;
}
.cursorBorder {
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 35px;
  height: 35px;
  z-index: 9001;
}
/* .cursor:nth-child(1) {
  background-color: #d26;
  z-index: 1;
} */
/* .cursor:nth-child(2) {
  background-color: rgb(0, 0, 0);
} */
</style>