<template>
  <v-app>
    <!-- <BarDesktop v-if="!hideBarDesktop" /> -->
    <v-app-bar class="bar_desktop" height="100" fixed color="white">
      <img
        @click="$router.push('/')"
        style="cursor: pointer"
        width="150"
        lazy-src="./assets/logo-bio.png"
        src="./assets/logo-bio.png"
      />

      <v-spacer></v-spacer>

      <!-- <v-btn text class="btn" @click="fnTo('/')"> Home </v-btn> -->
      <!--START BAR DESKTOP-->
      <div class="button">
        <v-btn text class="btn" @click="fnTo('/conocenos')"> Conócenos </v-btn>
        <v-menu rounded="b-xl" open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="btn" text v-bind="attrs" v-on="on"> Atención </v-btn>
          </template>

          <v-list>
            <v-list-item
              class="item"
              @click="fnTo('diagnostico_de_inversionista')"
            >
              <v-list-item-title>Diagnóstico de inversiones</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu rounded="b-xl" open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="btn" text v-bind="attrs" v-on="on"> Eventos </v-btn>
          </template>

          <v-list>
            <v-list-item
              class="item"
              @click="fnTo(item)"
              v-for="[item, index] in eventos"
              :key="index"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu rounded="b-xl" open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="btn" text v-bind="attrs" v-on="on"> Notas </v-btn>
          </template>

          <v-list>
            <v-list-item
              class="item"
              @click="fnTo(index)"
              v-for="[item, index] in notas"
              :key="index"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu rounded="b-xl" open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="btn" text v-bind="attrs" v-on="on">
              Account Manager
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              class="item"
              href="http://contratos.rootstoken.io/"
              target="_blank"
            >
              <v-list-item-title @click="sheet = false"
                >Contratos</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              class="item"
              href="https://www.facebook.com/groups/1080891862355635/?ref=share"
              target="_blank"
            >
              <v-list-item-title @click="sheet = false"
                >Capacitación Account Manager</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-btn
        color="#85af61"
        dark
        class="btn"
        href="https://pagos.magueytoken.com/"
        target="_blank"
      >
        Comienza a invertir
      </v-btn> -->
      </div>
      <!--END BAR DESKTOP-->
    </v-app-bar>
    <!-- <BarSheet v-if="hideBarDesktop && menu" /> -->
    <v-app-bar class="bar_desktop_min" height="100" fixed color="white">
      <img
        @click="$router.push('/')"
        style="cursor: pointer; padding: 20px"
        width="235"
        lazy-src="./assets/logo-bio.png"
        src="./assets/logo-bio.png"
      />

      <v-spacer></v-spacer>
      <!--START BOTTOM SHEET-->
      <v-bottom-sheet v-model="sheet">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="fnToConocenos">
            <v-list-item-icon>
              <v-icon>mdi-head-dots-horizontal-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Conócenos</v-list-item-title>
          </v-list-item>
          <v-list-group
            prepend-icon="mdi-face-agent"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title color="pink--text">Atención</v-list-item-title>
            </template>
            <v-list-item v-for="([name, to], i) in atencion" :key="i" link>
              <router-link class="link" :to="to"
                ><v-list-item-title
                  @click="sheet = false"
                  v-text="name"
                ></v-list-item-title
              ></router-link>
            </v-list-item>
          </v-list-group>
          <v-list-group
            prepend-icon="mdi-calendar-multiple"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title class="item-title">Eventos</v-list-item-title>
            </template>
            <v-list-item v-for="([name, to], i) in eventos" :key="i" link>
              <router-link class="link" :to="to"
                ><v-list-item-title
                  @click="sheet = false"
                  v-text="name"
                ></v-list-item-title
              ></router-link>
            </v-list-item>
          </v-list-group>
          <v-list-group
            prepend-icon="mdi-book-account-outline"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title class="item-title">Notas</v-list-item-title>
            </template>
            <v-list-item v-for="([name, to], i) in notas" :key="i" link>
              <router-link class="link" :to="to"
                ><v-list-item-title
                  @click="sheet = false"
                  v-text="name"
                ></v-list-item-title
              ></router-link>
            </v-list-item>
          </v-list-group>
          <v-list-group
            prepend-icon="mdi-account-child-outline"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title color="pink--text"
                >Account Manager</v-list-item-title
              >
            </template>
            <v-list-item
              :href="to"
              @click="drawer = !drawer"
              target="_blank"
              v-for="([name, to], i) in account"
              :key="i"
              link
            >
              <v-list-item-title v-text="name"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-bottom-sheet>
      <!--END BOTTOM SHEET-->
    </v-app-bar>
    <!-- <BarMobile v-if="mobile && hideBarDesktop" /> -->
    <div>
      <v-app-bar
        class="bar_mobile"
        fixed
        color="white"
        elevation="0"
        height="100"
      >
        <img
          @click="$router.push('/')"
          lazy-src="./assets/logo-bio.png"
          class="div_img_maguey_b"
          src="./assets/logo-bio.png"
        />
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon
          color="#85af61"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </v-app-bar>
      <v-navigation-drawer
        fixed
        v-model="drawer"
        temporary
        style="width: 100%; background: #fff"
      >
        <v-app-bar
          class="bar_mobile"
          fixed
          color="white"
          elevation="0"
          height="100"
        >
          <img
            @click="$router.push('/')"
            lazy-src="./assets/logo-bio.png"
            class="div_img_maguey_b"
            src="./assets/logo-bio.png"
          />
          <v-spacer></v-spacer>
          <v-icon color="#85af61" @click.stop="drawer = !drawer"
            >mdi-close-thick</v-icon
          >
        </v-app-bar>
        <v-list style="margin-top: 100px">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-title>HOME</v-list-item-title>
          </v-list-item>
          <v-list-item @click="fnToConocenos">
            <v-list-item-icon>
              <v-icon>mdi-head-dots-horizontal-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-title>CONÓCENOS</v-list-item-title>
          </v-list-item>
          <v-list-group
            prepend-icon="mdi-face-agent"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title color="pink--text">ATENCIÓN</v-list-item-title>
            </template>
            <v-list-item v-for="([name, to], i) in atencion" :key="i" link>
              <router-link class="link" :to="to"
                ><v-list-item-title
                  style="color: black; margin-left: 60px"
                  v-text="name"
                ></v-list-item-title
              ></router-link>
            </v-list-item>
          </v-list-group>
          <v-list-group
            prepend-icon="mdi-calendar-multiple"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title color="pink--text">EVENTOS</v-list-item-title>
            </template>
            <v-list-item v-for="([name, to], i) in eventos" :key="i" link>
              <router-link class="link" :to="to"
                ><v-list-item-title
                  style="color: black; margin-left: 60px"
                  v-text="name"
                ></v-list-item-title
              ></router-link>
            </v-list-item>
          </v-list-group>
          <v-list-group
            prepend-icon="mdi-book-account-outline"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title color="pink--text">Notas</v-list-item-title>
            </template>
            <v-list-item v-for="([name, to], i) in notas" :key="i" link>
              <router-link class="link" :to="to"
                ><v-list-item-title
                  style="color: black; margin-left: 60px"
                  v-text="name"
                ></v-list-item-title
              ></router-link>
            </v-list-item>
          </v-list-group>

          <v-list-group
            prepend-icon="mdi-account-child-outline"
            color="#85af61"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title color="pink--text"
                >ACCOUNT MANAGER</v-list-item-title
              >
            </template>
            <v-list-item
              style="color: black; margin-left: 60px"
              :href="to"
              @click="drawer = !drawer"
              target="_blank"
              v-for="([name, to], i) in account"
              :key="i"
              link
            >
              <v-list-item-title v-text="name"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!-- <v-list-item href="https://pagos.magueytoken.com/" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Comieza a invertir</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-navigation-drawer>
    </div>
    <v-main>
      <v-btn
        href="https://api.whatsapp.com/send?phone=525564931947&text=Hola,%20¿Me%20puedes%20brindar%20más%20información%20sobre%20BIOPLANTA?%20Visite%20la%20página%20http://bioplanta.io/"
        target="_blank"
        class="fab magnetic"
        fixed
        bottom
        right
        dark
        color="#25d366"
        elevation="2"
        fab
        width="60"
        height="60"
        ><v-icon large>mdi-whatsapp</v-icon></v-btn
      >
      <router-view />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
window.onload = function () {
  var magnets = document.querySelectorAll(".magnetic");
  var strength = 50;

  magnets.forEach((magnet) => {
    magnet.addEventListener("mousemove", moveMagnet);
    magnet.addEventListener("mouseout", function (event) {
      TweenMax.to(event.currentTarget, 1, { x: 0, y: 0, ease: Power4.easeOut });
    });
  });

  function moveMagnet(event) {
    var magnetButton = event.currentTarget;
    var bounding = magnetButton.getBoundingClientRect();

    //console.log(magnetButton, bounding)

    TweenMax.to(magnetButton, 1, {
      x:
        ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.8) *
        strength,
      y:
        ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.8) *
        strength,
      ease: Power4.easeOut,
    });

    //magnetButton.style.transform = 'translate(' + (((( event.clientX - bounding.left)/(magnetButton.offsetWidth))) - 0.5) * strength + 'px,'+ (((( event.clientY - bounding.top)/(magnetButton.offsetHeight))) - 0.5) * strength + 'px)';
  }
};
import Footer from "@/components/Footer2.vue";
import CursorA from "@/components/CursorB.vue";
import BarDesktop from "@/components/BarDesktop.vue";
import BarSheet from "@/components/BarSheet.vue";
import BarMobile from "@/components/BarMobile.vue";

export default {
  components: {
    Footer,
    CursorA,
    BarDesktop,
    BarSheet,
    BarMobile,
  },
  name: "App",

  data: () => ({
    sheet: false,
    tiles: [
      { img: "keep.png", title: "Keep" },
      { img: "inbox.png", title: "Inbox" },
      { img: "hangouts.png", title: "Hangouts" },
      { img: "messenger.png", title: "Messenger" },
      { img: "google.png", title: "Google+" },
    ],
    mobile: false,
    drawer: null,
    menu: false,
    hideBarDesktop: false,
    items: [
      { title: "Eventos", icon: "mdi-view-dashboard" },
      { title: "Nostros", icon: "mdi-view-dashboard" },
      { title: "Conócenos", icon: "mdi-forum" },
      { title: "Atención", icon: "mdi-view-dashboard" },
    ],

    // options: [
    //   { name: "Eventos" },
    //   { name: "Nosotros" },
    //   { name: "Conócenos" },
    //   { name: "Atención" },
    // ],
    eventos: [
      ["Webinar", "/"],
      // ["Mezcalizate", "/mezcalizate"],
      ["Experiencias", "/experiencias"],
    ],
    notas: [
      ["Noticias BIOPLANTA", "/noticias-bioplanta"],

      ["Prensa", "/prensa"],
    ],
    account: [
      ["Contratos", "http://contratos.rootstoken.io/"],
      [
        "Capacitación Account Managers",
        "https://www.facebook.com/groups/1080891862355635/?ref=share",
      ],
    ],
    atencion: [
      ["Diagnóstico de inversionista", "/diagnostico_de_inversionista"],
    ],
  }),
  created() {
    if (window.innerWidth > 600 && window.innerWidth < 1125) {
      this.menu = true;
      this.mobile = false;
    } else {
      this.menu = false;
      this.mobile = true;
    }
    if (window.innerWidth < 1125) {
      this.hideBarDesktop = true;
    } else {
      this.hideBarDesktop = false;
    }
  },
  methods: {
    // fnChangeMenu() {
    //   window.onresize = () => {
    //     if (window.innerWidth > 600 && window.innerWidth < 1125) {
    //       this.menu = true;
    //       this.mobile = false;
    //     } else {
    //       this.menu = false;
    //       this.mobile = true;
    //     }
    //     if (window.innerWidth < 1125) {
    //       this.hideBarDesktop = true;
    //     } else {
    //       this.hideBarDesktop = false;
    //     }
    //   };
    // },
    fnTo(ruta) {
      if (this.$route.path != ruta) {
        this.$router.push(ruta);
      }
    },
    fnToConocenos() {
      if (this.$route.path != "/conocenos") {
        this.$router.push("/conocenos");
      }
      this.sheet = false;
    },
    scrollTo(elementId) {
      const section = this.$el.querySelector(`.${elementId}`);

      if (this.$route.path != "/") {
        this.$router.push("/");
        setTimeout(() => {
          const section2 = this.$el.querySelector(`.${elementId}`);

          section2.scrollIntoView({ block: "end", inline: "end" });
        }, 100);
      } else {
        section.scrollIntoView({ block: "end", inline: "end" });
      }
      this.sheet = false;
      this.drawer = false;
    },
  },
  mounted() {
    window.addEventListener("resize", this.fnChangeMenu);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.fnChangeMenu);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
html,
body,
#app {
  font-family: "Poppins", sans-serif;
  /* cursor: none; */
  overflow: hidden;
  background-color: black;
}
.nav {
  /* z-index: 9000; */
}
.link {
  text-decoration: none;
  color: black;
}
.fab {
  /* cursor: none; */
}
.div_img_maguey_b {
  width: 150px;
}
.div_img_maguey_b-2 {
  width: 100px;
}
.link {
  text-decoration: none;
}
.link_conocenos {
  text-decoration: none;
  margin-left: 0;
}
.btn,
.item {
  /* cursor: none; */
}
.app_bar_desktop {
  z-index: 3;
}
.v-list-group {
  width: 100%;
}
@media screen and (min-width: 1125px) {
  .bar_desktop_min,
  .bar_mobile {
    display: none;
  }
}
@media screen and (min-width: 600px) and (max-width: 1125px) {
  .bar_mobile,
  .bar_desktop {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .bar_desktop,
  .bar_desktop_min {
    display: none;
  }
}
</style>