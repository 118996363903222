<template>
  <div class="main">
    <div @click="fnPlay" class="box-mov">
      <div class="mov-des">
        <div id="hover" class="des-text">
          <h3>Touch me</h3>
        </div>
      </div>
      <div class="mov-item">
        <video id="myVideo" autoplay muted width="90%" loop>
          <source src="../assets/retorno.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    fnPlay() {
      var video = document.getElementById("myVideo");
      var hover = document.getElementById("hover");

      if (video.muted) {
        video.muted = false;
        hover.style.display = "none";
      } else {
        video.muted = true;
        hover.style.display = "flex";
      }
    },
  },
};
</script>
<style scoped>
body {
  background-color: #000;
}

.box-mov {
  position: relative;
}
.box-mov .mov-des {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("http://mouse.latercera.com/wp-content/uploads/2018/03/Dragon-Ball-1-900x494.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.5s ease;
  opacity: 1;
}
.box-mov .mov-des .des-text {
  background-color: rgba(0, 0, 0, 0.493);
  padding: 1rem;
  color: #ffffff;
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5%; */
  border-radius: 10px;
}
.box-mov .mov-des .des-text p {
  margin: 0;
  text-transform: uppercase;
}
.box-mov .mov-des img {
  height: 100%;
}
.box-mov .mov-des.hide {
  opacity: 0;
  visibility: hidden;
}
.mov-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
video {
  border-radius: 10px;
  /* margin-top: 5%; */
}
h3 {
  color: #f6b81d;
  text-shadow: 10px 5px 5px black;
}
</style>