import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import VueScrollmagic from 'vue-scrollmagic'
import AOS from "aos"
import 'animate.css'
import 'aos/dist/aos.css'
// import Fullpage from "vue-fullpage.js";
// import "fullpage.js/dist/fullpage.css";
import Carousel3d from 'vue-carousel-3d';

Vue.use(Carousel3d);

// Vue.use(Fullpage)

// Vue.use(VueScrollmagic
//   , {
//     vertical: true,
//     globalSceneOptions: {
//       triggerHook: "onLeave",
//       duration: "200%"
//     },
//     loglevel: 2,
//     refreshInterval: 100
//   }
// );
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  mounted() {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
