<template>
  <div>
    <v-app-bar
      class="app_bar_desktop"
      fixed
      color="rgba(0,0,0,.7)"
      elevation="0"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title
        ><router-link to="/"
          ><v-img
            class="div_img_maguey_b"
            src="../assets/magueyB.png"
          ></v-img></router-link
      ></v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer fixed v-model="drawer" temporary>
      <v-list>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-group
          prepend-icon="mdi-calendar-multiple"
          color="#f6b81d"
          :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title color="pink--text">Eventos</v-list-item-title>
          </template>
          <v-list-item v-for="([name, to], i) in eventos" :key="i" link>
            <router-link class="link" :to="to"
              ><v-list-item-title v-text="name"></v-list-item-title
            ></router-link>
          </v-list-item>
        </v-list-group>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-book-account-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title @click="scrollTo('notas')"
            >Prensa</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="fnToConocenos">
          <v-list-item-icon>
            <v-icon>mdi-head-dots-horizontal-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Conócenos</v-list-item-title>
        </v-list-item>
        <v-list-group
          prepend-icon="mdi-face-agent"
          color="#f6b81d"
          :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title color="pink--text">Atención</v-list-item-title>
          </template>
          <v-list-item v-for="([name, to], i) in atencion" :key="i" link>
            <router-link class="link" :to="to"
              ><v-list-item-title v-text="name"></v-list-item-title
            ></router-link>
          </v-list-item>
        </v-list-group>
        <v-list-group
          prepend-icon="mdi-account-child-outline"
          color="#f6b81d"
          :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title color="pink--text"
              >Account Manager</v-list-item-title
            >
          </template>
          <v-list-item
            :href="to"
            @click="drawer = !drawer"
            target="_blank"
            v-for="([name, to], i) in account"
            :key="i"
            link
          >
            <v-list-item-title v-text="name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item href="https://pagos.magueytoken.com/" target="_blank">
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Comieza a invertir</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data: () => ({
    drawer: null,
    eventos: [
      ["Webinar", "/"],
      ["Mezcalizate", "/mezcalizate"],
      ["Experiencias", "/experiencias"],
    ],
    account: [
      ["Contratos", "https://contratos.cbimultiassets.com/"],
      [
        "Capacitación Account Managers",
        "https://www.facebook.com/groups/1080891862355635/?ref=share",
      ],
    ],
    atencion: [
      ["Diagnóstico de inversionista", "/diagnostico_de_inversionista"],
    ],
  }),
  methods: {
    fnToConocenos() {
      if (this.$route.path != "/conocenos") {
        this.$router.push("/conocenos");
      }
    },
    scrollTo(elementId) {
      const section = this.$el.querySelector(`.${elementId}`);

      if (this.$route.path != "/") {
        this.$router.push("/");
        setTimeout(() => {
          const section2 = this.$el.querySelector(`.${elementId}`);

          section2.scrollIntoView({ block: "end", inline: "end" });
        }, 100);
      } else {
        section.scrollIntoView({ block: "end", inline: "end" });
      }
      this.drawer = false;
    },
  },
};
</script>